import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import WebsiteOption from '../../app/dto/websiteOption';
import TranslationDataDto from '../../app/dto/translationDataDto';
import SendTranslations from '../../app/dto/translations/sendTranslations';
import mapToSendTranslations from '../../app/mappers/mapToSendTranslations';
import SendOperationParametersDto from '../../app/dto/operations/SendOperationParametersDto';
import ExecuteOperationDto from '../../app/dto/operations/ExecuteOperationDto';
import ResendParametersDto from '../../app/dto/resendParametersDto';
import { httpclient as Http } from 'typescript-http-client';

interface SendViewState {
  userId: string;
  userPassword: string;
  website: string;
  isTestMessage: boolean;
  websiteOptions: Array<WebsiteOption>;
  translationData: SendTranslations;
  isSending: boolean;
  isError: boolean;
  isSuccess: boolean;
  isResend: boolean;
}

const websiteOptions: Array<WebsiteOption> = [
  { name: 'Test site' },
  { name: 'Live site' }
];

const initialState: SendViewState = {
  websiteOptions: websiteOptions,
  userId: '',
  userPassword: '',
  isTestMessage: true,
  website: websiteOptions[0].name,
  translationData: mapToSendTranslations([]),
  isSending: false,
  isError: false,
  isSuccess: false,
  isResend: false,
};

export const slice = createSlice({
  name: 'send',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.userPassword = action.payload;
    },
    setWebsite: (state, action: PayloadAction<string>) => {
      state.website = action.payload;
    },
    setIsTestMsg: (state, action: PayloadAction<boolean>) => {
      state.isTestMessage = action.payload;
    },
    setTranslations: (state, action: PayloadAction<Array<TranslationDataDto>>) => {
      state.translationData = mapToSendTranslations(action.payload);
    },
    beginSending: (state) => {
      state.isSending = true;
    },
    finishSending: (state) => {
      state.isSending = false;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
    setResendParameters: (state, action: PayloadAction<ResendParametersDto>) =>{
      if(action.payload){
        state.isResend = true;
        state.isTestMessage = action.payload.isTestMsg;
        state.website = action.payload.isTestSite ? websiteOptions[0].name : websiteOptions[1].name;
      }
    }
  },
});

export const { setUserId, setPassword, setWebsite, setIsTestMsg, setTranslations, 
  beginSending, finishSending, setError, setSuccess, setResendParameters } = slice.actions;

export interface SendParameters {
  OperationId: string;
  User: string;
  Password: string;
  WebSite: string;
  IsTest: boolean;
  Timeout: number;
}

export function sendAsync(apiClient: Http.HttpClient, parameters: SendParameters): AppThunk {
  return async (dispatch) => {

    await dispatch(beginSending());

    try {

      const setOperationParameters = new Http.Request(`operations/${parameters.OperationId}/parameters`,
        {
          method: 'PUT',
          timeout: parameters.Timeout,
          body: {
            IsTestSite: (parameters.WebSite === 'Test site').toString(),
            IsTestMessage: parameters.IsTest.toString()
          } as SendOperationParametersDto
        });

      await apiClient?.execute<void>(setOperationParameters);

      const executeOperation = new Http.Request(`operations/${parameters.OperationId}/executions`,
        {
          method: 'POST',
          timeout: parameters.Timeout,
          body: {
            User: parameters.User,
            Password: parameters.Password,
          } as ExecuteOperationDto
        });

      await apiClient?.execute<void>(executeOperation);

    }
    catch(error){
      dispatch(setError(true));
      dispatch(finishSending());
    }
  };
}

export const isValid = (state: RootState) => state.sendForm.userId && state.sendForm.userPassword;

export default slice.reducer;
