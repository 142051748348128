import TranslationDataDto from '../dto/translationDataDto';
import SendTranslations from '../dto/translations/sendTranslations';
import _ from 'lodash';

export default function mapToSendTranslations(translations: Array<TranslationDataDto>): SendTranslations {
  const sendTranslations: SendTranslations = {
    SendDocumentsTitle: getTranslation(340002) || 'Send to HMRC',
    SendButton: getTranslation(39290) || 'Send',
    SendingStatus: 'Sending',
    UserId: getTranslation(60248) || 'User ID',
    Password: getTranslation(40951) || 'Password',
    Website: getTranslation(892358) || 'Website',
    TestMessage: getTranslation(339996) || 'Test message',
    NotEmptyValidation: getTranslation(340150) || 'Field must have a value',
    ModalErrorTitle: "Error",
    ModalSuccessTitle: "Success",
    OperationError: "Operation was completed with errors.",
    OperationSuccessfull: "Operation was completed successfully. Please close the window and refresh Unit4 ERP screen.",
    OperationInProgress: "Sending..."
  };

  function getTranslation(id: number): string | undefined {
    return _.find(translations, (t: TranslationDataDto) => t.id === id)?.text;
  }

  return sendTranslations;
}
