import { httpclient } from 'typescript-http-client';
import { RootState, useSelector } from '../app/store';

export interface AppConfig {
  apiUri: string;
  appUri: string;
  translationApiUri: string;
  oidcAuthority: string;
  appVersion: string;
  requestTimeout: number;
}

export function getConfigAsync(): Promise<AppConfig> {
  const client = httpclient.newHttpClient();

  return client.execute<AppConfig>(new httpclient.Request(`${process.env.PUBLIC_URL}/configuration/spa`));
}

export function useConfig(): AppConfig {
  return useSelector((state: RootState) => state.config);
}