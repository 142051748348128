import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { getConfigAsync } from './config/appconfig';
import App from './App';
import { getStore } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

getConfigAsync().then(appConfig => {
  ReactDOM.render(
    <Provider store={getStore(appConfig)}>
      <App />
    </Provider>,
    document.getElementById('root')
  );

  serviceWorker.unregister();
});