import Components from '@u4/next-components';
import React from 'react';

interface IModalProps {
    isVisible: boolean,
    title: string,
    body: string,
    type: string,
    onClose: () => void,
    notClosable: boolean
}

function Modal(props : IModalProps){

    return (
    <Components.Modal
            title={props.title}
            modalType={props.type}
            showIcon={true}
            showModal={props.isVisible}
            onClose={props.onClose}
            notClosable={props.notClosable}
            footer={
              <React.Fragment>
              </React.Fragment>
            }>
            <Components.Container isColumn>
              {props.body}
            </Components.Container>
          </Components.Modal>
    )
}

export default Modal;