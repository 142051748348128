import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Components, { textFieldValidationStates } from '@u4/next-components';
import '@u4/next-components/lib/style.css';
import {
  setUserId,setPassword, setWebsite,
  setIsTestMsg, setTranslations, sendAsync, isValid,
  setError, setSuccess, finishSending, setResendParameters
} from './sendFormSlice';
import TranslationDataDto from '../../app/dto/translationDataDto';
import { sendTranslationRequest, sendApiRequest } from '../../app/sender';
import { useAccessToken } from '../../app/authorization';
import { useApi } from '../../app/apiClient';
import { RootState } from '../../app/store';
import Modal from '../Modal/Modal';
import './sendFormStyles.css';
import ResendParametersDto from '../../app/dto/resendParametersDto';
import OperationStatusDto from '../../app/dto/operationStatusDto';
import { useConfig } from '../../config/appconfig';

type SendFormProps = {
  canBeTestMsg: boolean;
}

export const SendForm: FunctionComponent<SendFormProps> = ({canBeTestMsg}) => {
  const { userId, password, website, isTestMsg, websiteOptions, isSending, translations, isError, isSuccess, isResend } = useSelector(
    (state: RootState) => {
      return {
        userId: state.sendForm.userId,
        password: state.sendForm.userPassword,
        website: state.sendForm.website,
        isTestMsg: state.sendForm.isTestMessage,
        websiteOptions: state.sendForm.websiteOptions,
        translations: state.sendForm.translationData,
        isSending: state.sendForm.isSending,
        isError: state.sendForm.isError,
        isSuccess: state.sendForm.isSuccess,
        isResend: state.sendForm.isResend
      };
    }
  );

  const config = useConfig();
  const isDataValid = useSelector(isValid);
  const token = useAccessToken();
  const [validate, setValidate] = useState(false);
  const dispatch = useDispatch();
  const { operationId } = useParams();
  const apiClient = useApi();
  let interval: number;

  useEffect(() => {
    function fetchTranslations(): void {
      sendTranslationRequest('/bundles/hmrcSend', config, token)
        .then((res: TranslationDataDto[]) => dispatch(setTranslations(res)));
    }

    checkIfCanBeSendAsTest();

    if (token) {
      fetchResendParameters();
      fetchTranslations();
    }
  }, [token, dispatch, canBeTestMsg]);

  function handleUserIdChange(e: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(setUserId(e.target.value));
  }

  function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(setPassword(e.target.value));
  }

  function handleWebsiteChange(e: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(setWebsite(e.target.value));
  }

  function handleTestMessageChange(e: React.ChangeEvent<HTMLInputElement>): void {
    dispatch(setIsTestMsg(e.target.checked));
  }

  function startInterval(): void{
    interval = window.setInterval(() => {
      fetchOperationStatus();
    }, 5000);    
  }

  function checkIfCanBeSendAsTest(){
    if(!canBeTestMsg)
      dispatch(setIsTestMsg(false));
  }

  async function handleSend(_e: React.MouseEvent): Promise<void> {
    setValidate(true);
    if (operationId) {
      await dispatch(sendAsync(
        apiClient,
        {
          OperationId: operationId,
          User: userId,
          Password: password,
          WebSite: website,
          IsTest: isTestMsg,
          Timeout: config.requestTimeout
        }));

        if(!isError){
          startInterval();
        }
    }
  }

  async function fetchOperationStatus(): Promise<void> {
    const response = await sendApiRequest<OperationStatusDto>(`operations/${operationId}/status`, 'GET', config, token);

    checkOperationStatus(response);
  }

  async function fetchResendParameters(): Promise<void> {
    const response = await sendApiRequest<ResendParametersDto>(
      'documentHistory/' + operationId + '/parameters',
      'GET', config, token);

    dispatch(setResendParameters(response));
  }

  function checkOperationStatus(res: OperationStatusDto): void {
    switch(res.status) {
      case 'Done': {
        window.clearInterval(interval);
        dispatch(setSuccess(true));
        dispatch(finishSending());
        break;
      }
      case 'Error': {
        updateTranslation(res.errorMessage);
        window.clearInterval(interval);
        dispatch(setError(true));
        dispatch(finishSending());
        break;
      }
    }
  }

  function updateTranslation(message: string): void {
    if (message != '') {
      translations.OperationError = message;
    }
  }

  return (
    <Components.Container isColumn>
      <Components.Panel
        header={translations.SendDocumentsTitle}
        maxWidth='50vw'
        flex='auto'
        isColumn
        footer={
          <Components.Container isRow>
          <Components.Button
            name='send'
            text={isSending ? translations.SendingStatus : translations.SendButton}
            size='small'
            type='HAPPY'
            onClick={handleSend}
            disabled={isSending || !isDataValid}
          />
          <Components.DisplayField
          value={translations.OperationInProgress} 
          dataType="text"
          cssClass={isSending ? '' : 'hidden'}
          />
          </Components.Container>
        }>
        <Components.InputField
          name='userId'
          label={translations.UserId}
          dataKey='userId'
          dataType='String'
          size='large'
          value={userId}
          onChange={handleUserIdChange}
          mandatory={true}
          validationStatus={(validate && !userId) ? textFieldValidationStates.ERROR : ''}
          description={(validate && !userId) ? translations.NotEmptyValidation : ''}
        />
        <Components.InputField
          name='password'
          label={translations.Password}
          dataKey='password'
          dataType='password'
          size='large'
          value={password}
          onChange={handlePasswordChange}
          mandatory={true}
          validationStatus={(validate && !password) ? textFieldValidationStates.ERROR : ''}
          description={(validate && !password) ? translations.NotEmptyValidation : ''}
        />
        <Components.Select
          name='website'
          fieldName='website'
          label={translations.Website}
          options={websiteOptions}
          displayField='name'
          valueField='name'
          size='large'
          value={website}
          onChange={handleWebsiteChange}
          mandatory={true}
          disabled={isResend}
          cssClass={isResend ? 'disabledField form__item--large' : 'form__item--large'}
        />
        <Components.Switch
          cssClass='happy-checkbox background-white-checkbox'
          name='isTestMessage'
          label={translations.TestMessage}
          checked={isTestMsg}
          onChange={handleTestMessageChange}
          mandatory={true}
          disabled={isResend || !canBeTestMsg}
        />
      </Components.Panel>
      <Modal 
        onClose={()=> dispatch(setSuccess(false))} 
        isVisible={isSuccess} 
        type="success"
        notClosable={false}
        body={translations.OperationSuccessfull}
        title={translations.ModalSuccessTitle}/>  
      <Modal 
        onClose={()=> dispatch(setError(false))} 
        isVisible={isError} 
        type="warning" 
        notClosable={false}
        body={translations.OperationError}
        title={translations.ModalErrorTitle}/>
    </Components.Container>
  );
}