import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Components from '@u4/next-components';
import { sendApiRequest} from '../../app/sender';
import { useAccessToken } from '../../app/authorization';
import OperationDto from '../../app/dto/operationDto';
import { SendForm } from '../SendForm/sendForm';
import { RetrieveForm } from '../RetrieveForm/retrieveForm';
import { useConfig } from '../../config/appconfig';
import { ValidationError } from '../../app/errors/ValidationError';
import { useDispatch, useSelector } from 'react-redux';
import { setValidation } from '../Validation/validationSlice';
import { RootState } from '../../app/store';
import Modal from '../Modal/Modal';

export const Operation: React.FunctionComponent = () => {
  const { translations, isValidated, validationMessage } = useSelector(
      (state: RootState) => {
        return {
          translations: state.retrieveForm.translations,
          isValidated: state.validation.isValidated,
          validationMessage: state.validation.validationMessage
        };
      }
    );
    
  const config = useConfig();
  const dispatch = useDispatch();
  const token = useAccessToken();
  const { operationId } = useParams();
  const [operationType, setOperationType] = useState('');
  const [canBeTestMessage, setCanBeTestMsg] = useState(true);

  function renderSwitch(type: string, canBeTestMessage: boolean) {
    switch(type) {
      case 'Send':
        return <SendForm canBeTestMsg={canBeTestMessage}/>;
      case 'Retrieve':
        return <RetrieveForm/>;
      default:
        return <div />;
    }
  }

  useEffect(() => {
    if(token){
        sendApiRequest<OperationDto>(`operations/${operationId}`, 'GET', config, token)
        .then(res => {
          setOperationType(res.type);
          setCanBeTestMsg(res.canBeTestMessage);
        }).catch(e => {
          if (e instanceof ValidationError) {
            dispatch(setValidation({isValidated: false, validationMessage: e.message }));
          } else {
            throw e;
          }
        });
    }
  }, [token]);

  return (  
    <Components.Container isColumn>
     {renderSwitch(operationType, canBeTestMessage)}
     <Modal 
        onClose={()=> {}} 
        isVisible={!isValidated} 
        type="warning" 
        body={validationMessage} 
        notClosable={true}
        title={translations.ModalErrorTitle}/> 
    </Components.Container>
  );
};