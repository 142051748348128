import { AppConfig } from './appconfig';

export default function getOidcSettings(redirectUrl: string, appConfig: AppConfig) {
  return {
    authority: appConfig.oidcAuthority,
    client_id: 'u4-hmrc-implicit',
    redirect_uri: `${appConfig.appUri}?redirect=${redirectUrl}`,
    response_type: 'id_token token',
    scope: 'openid profile u4bw u4erp u4ts',
    post_logout_redirect_uri: `${appConfig.appUri}`
  };
}